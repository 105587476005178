





































































































































































































































































































































import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { Component, Watch } from 'vue-property-decorator';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import { namespace } from 'vuex-class';
import SurveyQuestion from '@/models/graphql/SurveyQuestion';
import SingleSelectComponent from '@/components/form/SingleSelectComponent.vue';
import SurveyQuestionType from '@/utils/enums/SurveyQuestionType';
import ChoiceParams from '@/utils/types/ChoiceParams';
import SurveyQuestionChoice from '@/models/graphql/SurveyQuestionChoice';
import ButtonComponent from '@/components/ButtonComponent.vue';
import MultiSelectComponent from '@/components/form/MultiSelectComponent.vue';
import SurveyUserAnswer from '@/models/graphql/SurveyUserAnswer';
import RatingNumberComponent from '@/components/form/RatingNumberComponent.vue';
import RatingStarComponent from '@/components/form/RatingStarComponent.vue';
import DropdownComponent from '@/components/form/DropdownComponent.vue';
import InputText from '@/components/InputText.vue';
import TextAreaComponent from '@/components/TextAreaComponent.vue';
import EntityType from '@/utils/enums/EntityType';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import ActionType from '@/utils/enums/ActionType';
import { SurveyQuestionFilter } from '@/graphql/_Filters/SurveyQuestionFilter';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import { SurveyUserAnswerFilter } from '@/graphql/_Filters/SurveyUserAnswerFilter';
import { SurveyFilter } from '@/graphql/_Filters/SurveyFilter';
import Survey from '@/models/graphql/Survey';
import SurveyStrategy from '@/utils/enums/SurveyStrategy';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import ToastActionType from '@/utils/enums/ToastActionType';
import ToastActionParams from '@/utils/types/ToastActionParams';
import { SessionFilter } from '@/graphql/_Filters/SessionFilter';
import Session from '@/models/graphql/Session';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import { mixins } from 'vue-class-component';
import NodeEntity from '@/models/graphql/NodeEntity';

const surveyStore = namespace('SurveyStore');
const surveyQuestionStore = namespace('SurveyQuestionStore');
const surveyUserAnswerStore = namespace('SurveyUserAnswerStore');
const toastStore = namespace('ToastStore');
const sessionStore = namespace('SessionStore');
const nodeStore = namespace('NodeStore');

@Component({
  components: {
    AvatarSoloWidget,
    ConfirmModal,
    TextAreaComponent,
    InputText,
    DropdownComponent,
    RatingStarComponent,
    RatingNumberComponent,
    MultiSelectComponent,
    ButtonComponent,
    SingleSelectComponent,
    FontAwesomeComponent,
    ButtonIconComponent,
  },
})
export default class SurveyWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  @surveyQuestionStore.Getter
  private fetchSurveyQuestions!: SurveyQuestion[];

  @surveyQuestionStore.Action
  private loadSurveyQuestions!: (filter: SurveyQuestionFilter) => Promise<SurveyQuestion>;

  @surveyUserAnswerStore.Action(ActionType.CREATE)
  private createSurveyUserAnswer!: (payload: {
    answer: SurveyUserAnswer;
    userId: string;
    surveyId: string;
    choiceId: string;
  })
    => Promise<SurveyQuestionChoice | undefined>;

  @surveyUserAnswerStore.Action
  private surveyUserAnswerSetEntity!: (payload: { uid: string; entityUid: string; entityType: string })
    => Promise<SurveyUserAnswer | undefined>;

  @surveyUserAnswerStore.Action(ActionType.GET_ONE)
  private getAnswer!: (payload: { filter: SurveyUserAnswerFilter }) => Promise<SurveyUserAnswer>;

  @surveyStore.Action(ActionType.GET_ONE)
  private getSurvey!: (payload: { filter: SurveyFilter; fragmentName?: string }) => Promise<Survey>;

  @surveyStore.Getter
  private survey!: Survey;

  @surveyStore.Getter
  private isLoading!: boolean;

  @sessionStore.Action(ActionType.GET_ONE)
  private getSession!: (payload: { filter: SessionFilter }) => Promise<Session | undefined>;

  @sessionStore.Getter
  private session!: Session;

  @nodeStore.Action(ActionType.GET_ONE)
  private getNode!: (uid: string) => Promise<NodeEntity | undefined>;

  private FileResourceHelper = FileResourceHelper;

  private entityCode = '';

  private linkedEntityType = '';

  private linkedEntityUid = '';

  private entityType: EntityType | null = null;

  private EntityType = EntityType;

  private questions: SurveyQuestion[] = [];

  private SurveyQuestionTypes = SurveyQuestionType;

  private step = 1;

  private saveLoading = false;

  private surveyAnswered = false;

  private surveyUnavailable = false;

  private answers: {
    index: number;
    questionUid: string;
    answers: SurveyUserAnswer[];
  }[] = [];

  protected get logoPath(): string {
    if (this.community?.logoFileResource?.path) {
      return FileResourceHelper.getFullPath(this.community?.logoFileResource, 'w56') !== ''
        ? FileResourceHelper.getFullPath(this.community?.logoFileResource, 'w56')
        : '';
    }
    return this.community?.logoFileResource?.path || '';
  }

  private get fieldClass(): string {
    if (this.isDesktop) {
      return 'field-lg';
    }
    return this.isTablet ? 'field-md' : 'field-sm';
  }

  private get continueButtonDisabled(): boolean {
    let invalidAnswer = false;
    const choices = this.getSelectedChoices(this.step);
    if (choices) {
      choices.forEach((selectedChoice) => {
        if (selectedChoice.isOther && selectedChoice.text === '') {
          invalidAnswer = true;
        }
      });
    }
    return invalidAnswer || (this.questions && this.questions.length > 0
      && !this.questions[this.step - 1].optional && this.findAnswerIndex(this.step) === -1);
  }

  created(): void {
    this.setDataConfig();
    const { entityId } = this.$route.params;
    if (entityId) {
      this.getNode(entityId).then((response) => {
        if (response) {
          // eslint-disable-next-line no-underscore-dangle
          this.entityType = response.__typename as EntityType;
          this.updateSurvey();
        } else {
          this.$router.push({ name: 'not-found' });
        }
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  mounted(): void {
    const smartBannerElements = document.getElementsByClassName('smartbanner');
    if (smartBannerElements && smartBannerElements.length > 0) {
      const smartBannerElement = smartBannerElements[0];
      smartBannerElement.remove();
    }
  }

  private updateSurvey(): void {
    const { entityId } = this.$route.params;
    if (entityId) {
      let feedbackSurveyExtraParam = {};
      let feedBackSurveyAnswerExtraParam = {};
      let extraParam = {};
      let extraNullParam = {};
      let strategy = '';
      switch (this.entityType) {
        case EntityType.SESSION:
          this.linkedEntityType = EntityType.SESSION;
          this.linkedEntityUid = entityId;
          extraParam = { session: { uid: entityId } };
          extraNullParam = { session: null };
          strategy = SurveyStrategy.SESSION_SURVEY;
          this.getSession({ filter: { uid: entityId } }).then((session) => {
            if (!session) {
              this.$router.push({ name: 'not-found' });
            }
          });
          break;
        case EntityType.SURVEY:
          extraParam = {};
          feedbackSurveyExtraParam = { uid: entityId };
          feedBackSurveyAnswerExtraParam = { survey: { uid: entityId } };
          strategy = SurveyStrategy.FEEDBACK_SURVEY;
          break;
        default:
          break;
      }

      const now = DateTimeHelper.currentTimestamp;
      /* eslint-disable @typescript-eslint/camelcase */
      this.getSurvey({
        filter: {
          ...extraParam,
          ...feedbackSurveyExtraParam,
        },
      }).then((survey) => {
        if (survey) {
          if (survey.title) {
            document.title = survey.title;
          }
          if ((survey.endTimestamp && survey.endTimestamp <= now)
            || (survey.startTimestamp && survey.startTimestamp >= now)) {
            this.surveyUnavailable = true;
          }
          this.entityCode = survey.uid;
          this.getAnswer({
            filter: {
              user: { uid: this.authUser.uid },
              ...extraParam,
              ...feedBackSurveyAnswerExtraParam,
            },
          }).then((response) => {
            if (response) {
              this.surveyAnswered = response && survey.uid === response.survey?.uid;
            } else {
              this.surveyAnswered = false;
            }
            if (!this.surveyAnswered) {
              this.loadQuestions();
            }
          });
        } else {
          this.getSurvey({
            filter: {
              strategy,
              ...extraNullParam,
            },
          }).then((survey2) => {
            if (survey2) {
              if (survey2.title) {
                document.title = survey2.title;
              }
              if ((survey2.endTimestamp && survey2.endTimestamp <= now)
                || (survey2.startTimestamp && survey2.startTimestamp >= now)) {
                this.surveyUnavailable = true;
              }
              this.entityCode = survey2.uid;
              this.getAnswer({
                filter: {
                  user: { uid: this.authUser.uid },
                  ...extraParam,
                },
              }).then((response) => {
                if (response) {
                  this.surveyAnswered = response && survey2.uid === response.survey?.uid;
                } else {
                  this.surveyAnswered = false;
                }
                if (!this.surveyAnswered) {
                  this.loadQuestions();
                }
              });
            } else {
              this.$router.push({ name: 'not-found' });
            }
          });
        }
      });
    }
  }

  private loadQuestions(): void {
    if (this.entityCode) {
      this.loadSurveyQuestions({
        survey: { uid: this.entityCode },
        // eslint-disable-next-line @typescript-eslint/camelcase
        choices_not: null,
      });
    }
  }

  @Watch('fetchSurveyQuestions', { immediate: true })
  private setQuestions(): void {
    if (this.fetchSurveyQuestions && this.fetchSurveyQuestions.length) {
      this.questions = this.fetchSurveyQuestions;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private getQuestionChoices(question: SurveyQuestion): ChoiceParams[] {
    return question.choices ? question.choices.map((choice: SurveyQuestionChoice) => ({
      value: choice.uid,
      text: choice.label,
      isOther: choice.isOther,
    } as ChoiceParams)) : [];
  }

  private onMultipleSelectChange(choices: ChoiceParams[]): void {
    let isNew = false;
    if (this.answers.length >= 1) {
      const answerIndex = this.findAnswerIndex(this.step);
      if (answerIndex > -1) {
        if (choices.length) {
          this.answers[answerIndex].answers = [];
          const finalAnswers: SurveyUserAnswer[] = [];
          choices.forEach((choice: ChoiceParams) => {
            const newAnswer = {
              choice: {
                uid: choice?.value,
                label: choice?.text,
                isOther: choice?.isOther,
              } as SurveyQuestionChoice,
            } as SurveyUserAnswer;

            finalAnswers.push(newAnswer);
          });
          this.answers[answerIndex].answers = finalAnswers;
        } else {
          this.answers.splice(answerIndex, 1);
        }
      } else {
        isNew = true;
      }
    } else {
      isNew = true;
    }

    if (isNew) {
      const finalAnswers: SurveyUserAnswer[] = [];
      choices.forEach((choice: ChoiceParams) => {
        const newAnswer = {
          choice: {
            uid: choice?.value,
            label: choice?.text,
            isOther: choice?.isOther,
          } as SurveyQuestionChoice,
        } as SurveyUserAnswer;

        finalAnswers.push(newAnswer);
      });
      this.answers.push({
        index: this.step,
        questionUid: this.questions[this.step - 1].uid,
        answers: finalAnswers,
      });
    }
  }

  private onSingleSelectChange(choice: ChoiceParams | null): void {
    let isNew = false;
    if (this.answers.length >= 1) {
      const answerIndex = this.findAnswerIndex(this.step);
      if (answerIndex > -1) {
        if (this.answers[answerIndex].answers.length) {
          if (choice) {
            this.answers[answerIndex].answers[0].choice = {
              uid: choice?.value,
              label: choice?.text,
              isOther: choice?.isOther,
            } as SurveyQuestionChoice;
          } else {
            this.answers.splice(answerIndex, 1);
          }
        }
      } else {
        isNew = true;
      }
    } else {
      isNew = true;
    }
    if (isNew && choice) {
      const newAnswer = {
        choice: {
          uid: choice?.value,
          label: choice?.text,
          isOther: choice.isOther,
        } as SurveyQuestionChoice,
      } as SurveyUserAnswer;
      this.answers.push({
        index: this.step,
        questionUid: this.questions[this.step - 1].uid,
        answers: [newAnswer],
      });
    }
  }

  private onRatingChange(rating: number): void {
    const question = this.questions[this.step - 1];
    const choice = question.choices ? question.choices[0] : null;
    let isNew = false;
    if (this.answers.length >= 1) {
      const answerIndex = this.findAnswerIndex(this.step);
      if (answerIndex > -1) {
        if (this.answers[answerIndex].answers.length) {
          if (rating) {
            if (choice) {
              this.answers[answerIndex].answers[0].choice = {
                uid: choice.uid,
                label: rating.toString(),
                isOther: true,
              } as unknown as SurveyQuestionChoice;
            }
          } else {
            this.answers.splice(answerIndex, 1);
          }
        }
      } else {
        isNew = true;
      }
    } else {
      isNew = true;
    }
    if (isNew && rating && choice) {
      const newAnswer = {
        choice: {
          uid: choice.uid,
          label: rating.toString(),
          isOther: true,
        } as unknown as SurveyQuestionChoice,
      } as SurveyUserAnswer;
      this.answers.push({
        index: this.step,
        questionUid: this.questions[this.step - 1].uid,
        answers: [newAnswer],
      });
    }
  }

  private onTextAnswerChange(text: string): void {
    const question = this.questions[this.step - 1];
    const choice = question.choices ? question.choices[0] : null;
    let isNew = false;
    if (this.answers.length >= 1) {
      const answerIndex = this.findAnswerIndex(this.step);
      if (answerIndex > -1) {
        if (this.answers[answerIndex].answers.length) {
          if (text.length && choice) {
            this.answers[answerIndex].answers[0].choice = {
              uid: choice.uid,
              label: text,
              isOther: true,
            } as unknown as SurveyQuestionChoice;
          } else {
            this.answers.splice(answerIndex, 1);
          }
        }
      } else {
        isNew = true;
      }
    } else {
      isNew = true;
    }
    if (isNew && text.length && choice) {
      const newAnswer = {
        choice: {
          uid: choice.uid,
          label: text,
          isOther: true,
        } as unknown as SurveyQuestionChoice,
      } as SurveyUserAnswer;
      this.answers.push({
        index: this.step,
        questionUid: this.questions[this.step - 1].uid,
        answers: [newAnswer],
      });
    }
  }

  private findAnswerIndex(step: number): number {
    return this.answers.findIndex((
      answer: {index: number; answers: SurveyUserAnswer[]},
    ) => answer.index === step);
  }

  private getSelectedChoices(step: number): ChoiceParams[] | null {
    if (this.answers.length >= 1) {
      const answerIndex = this.findAnswerIndex(step);
      if (answerIndex > -1) {
        return this.answers[answerIndex].answers.length
          ? this.answers[answerIndex].answers.map((answer: SurveyUserAnswer) => ({
            value: answer.choice.uid,
            text: answer.choice.label,
            isOther: answer.choice.isOther,
          } as ChoiceParams))
          : null;
      }
    }
    return null;
  }

  private onContinueClick(): void {
    this.step += 1;
  }

  private onGoBackClick(): void {
    this.step -= 1;
  }

  private onClose(): void {
    const answerCount = this.answers.reduce((previousValue, currentValue) => previousValue + currentValue.answers.length, 0);
    this.saveLoading = true;
    if (answerCount > 0) {
      this.answers.forEach((question: {
        index: number;
        questionUid: string;
        answers: SurveyUserAnswer[];
      }) => {
        let promises = [];
        promises = question.answers.map((answer: SurveyUserAnswer) => this.createSurveyUserAnswer({
          answer: {
            label: answer.choice.isOther ? answer.choice.label : '',
          } as SurveyUserAnswer,
          userId: this.authUser.uid,
          surveyId: this.entityCode,
          choiceId: answer.choice.uid,
        })
          .then((createdAnswer) => {
            if (createdAnswer) {
              return this.getLinkedEntityPromise((createdAnswer as unknown as SurveyUserAnswer).uid)
                .then((response) => response);
            }
            return Promise.resolve(undefined);
          }));
        Promise.all(promises).then((response) => {
          if (response) {
            this.saveLoading = false;
            const nomadBridge = (window as typeof window & {bridge?: { callSmartAction?: Function}}).bridge;
            if (nomadBridge?.callSmartAction) {
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              nomadBridge.callSmartAction('smartAction', 'closeCurrentPage', () => {}, () => {});
            }
          }
        });
      });
    }
  }

  private getLinkedEntityPromise(answerUid: string):
    Promise<SurveyUserAnswer | undefined> {
    switch (this.linkedEntityType) {
      case EntityType.SESSION:
        return this.surveyUserAnswerSetEntity({
          uid: answerUid,
          entityUid: this.linkedEntityUid,
          entityType: EntityType.SESSION,
        });
      case EntityType.SPEAKER:
        return this.surveyUserAnswerSetEntity({
          uid: answerUid,
          entityUid: this.linkedEntityUid,
          entityType: EntityType.SPEAKER,
        });
      case EntityType.EXHIBITOR:
        return this.surveyUserAnswerSetEntity({
          uid: answerUid,
          entityUid: this.linkedEntityUid,
          entityType: EntityType.EXHIBITOR,
        });
      case EntityType.PRODUCT:
        return this.surveyUserAnswerSetEntity({
          uid: answerUid,
          entityUid: this.linkedEntityUid,
          entityType: EntityType.PRODUCT,
        });
      default:
        return Promise.resolve(undefined);
    }
  }

  private onCloseButtonClick(): void {
    if (!this.surveyAnswered && !this.surveyUnavailable) {
      this.$bvModal.show('leave-modal-0');
    } else if (this.session) {
      this.$router.push({ name: 'session-detail', params: { sessionId: this.session.uid } });
    }
  }

  private onLeave(): void {
    if (this.session && this.session.uid) {
      this.$router.push({ name: 'session-detail', params: { sessionId: this.session.uid } });
    } else {
      this.$router.push({ path: '/' });
    }
  }

  @Watch('saveLoading')
  private saveLoadingChange(): void {
    if (!this.saveLoading && this.session && this.session.uid) {
      this.showToast(ToastActionType.FEEDBACK_SENT);
      this.$router.push({ name: 'session-detail', params: { sessionId: this.session.uid } });
    } else if (!this.saveLoading) {
      this.$router.push({ path: '/' });
    }
  }

  private showToast(type: ToastActionType): void {
    this.addNewAction({ type });
  }
}
