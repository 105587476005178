var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isReadyToDisplay)?_c('div',{class:[_vm.classList, 'survey-widget position-relative']},[_c('div',{staticClass:"close-header bg-white-white-100 position-fixed\n      d-flex align-items-center justify-content-between top-0 left-0 right-0 py-lg-4 py-3 px-lg-5 px-md-5 px-3"},[_c('b-link',{staticClass:"main-logo",attrs:{"to":{ path: '/' }}},[_c('avatar-solo-widget',{attrs:{"size":_vm.isDesktop ? '56' : '40',"square":true,"src":_vm.FileResourceHelper.getImagePathWithSize(_vm.logoPath, 'w56'),"entity":"Exhibitor"}})],1),(_vm.survey.strategy !== 'micropoll')?_c('button-component',{attrs:{"rounded":true,"size":_vm.isDesktop ? 'lg' : 'md',"text":("" + (_vm.$t('survey.close-button'))),"variant":"tertiary"},on:{"on-click":_vm.onCloseButtonClick},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('font-awesome-component',{attrs:{"container-classes":"icon-24","icon":"fa-regular fa-xmark"}})]},proxy:true}],null,false,3350905018)}):_vm._e()],1),_c('div',{staticClass:"container overflow-y-scroll"},[((!_vm.surveyAnswered && !_vm.surveyUnavailable) && !_vm.isLoading)?_c('div',{staticClass:"w-100 h-100 survey-questions-container",class:[
        {'lg': _vm.isDesktop},
        {'md': _vm.isTablet},
        {'sm': _vm.isMobile} ]},[(_vm.survey.strategy !== 'micropoll' && _vm.questions.length && _vm.step <= _vm.questions.length)?_c('div',{class:[
          {'ml-6': _vm.isDesktop},
          {'ml-0': !_vm.isDesktop} ]},[_c('p',{class:[
            'mb-0 caps caps-1 text-left text-neutral-n-6-label font-weight-bold' ]},[_vm._v(" "+_vm._s(_vm.entityType === _vm.EntityType.SURVEY ? ("" + (_vm.$t('survey.title'))) : (_vm.survey ? _vm.survey.title : ''))+" ")]),(_vm.session)?_c('h3',{staticClass:"m-0 text-left"},[_vm._v(" "+_vm._s(_vm.entityType === _vm.EntityType.SURVEY ? (_vm.survey ? _vm.survey.title : '') : _vm.session.name)+" ")]):_vm._e(),(_vm.survey && _vm.survey.instructions)?_c('p',{staticClass:"m-0 mt-2 text-neutral-n-8-dark-ink text-left"},[_vm._v(" "+_vm._s(_vm.survey.instructions)+" ")]):_vm._e(),_c('b-progress',{class:[
            'step-progress',
            {'mt-5 mb-5 lg': _vm.isDesktop},
            {'mt-5 mb-4 md': _vm.isTablet},
            {'mt-4 mb-4 sm': _vm.isMobile} ]},[_c('b-progress-bar',{attrs:{"max":_vm.questions.length,"value":_vm.step}})],1)],1):_vm._e(),(_vm.questions.length >= _vm.step)?_c('div',{class:[
          {'d-flex align-items-start justify-content-start column-gap-6': _vm.isDesktop},
          {'d-flex flex-column align-items-start justify-content-start': !_vm.isDesktop},
          {'row-gap-2': _vm.isMobile},
          {'row-gap-4': _vm.isTablet} ]},[(_vm.survey.strategy !== 'micropoll')?_c('div',{staticClass:"d-flex align-items-center justify-content-start question-step"},[_c('h4',{staticClass:"text-blue-b-3-primary-blue mb-0 mr-1"},[_vm._v(" "+_vm._s(_vm.step)+" ")]),_c('font-awesome-component',{attrs:{"container-classes":"icon-24","icon":"fa-regular fa-arrow-right","icon-classes":"text-blue-b-3-primary-blue"}})],1):_vm._e(),_c('div',{staticClass:"d-flex flex-column text-left overflow-auto w-100"},[_c('div',{staticClass:"mb-3 mb-sm-3 mb-md-4 mb-lg-4"},[_c('h4',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(("" + (_vm.questions && _vm.questions.length ? _vm.questions[_vm.step - 1].label : '')))+" ")]),(_vm.questions && _vm.questions.length && _vm.questions[_vm.step - 1].description)?_c('p',{staticClass:"text-neutral-n-5-placeholder m-0"},[_vm._v(" "+_vm._s(_vm.questions[_vm.step - 1].description)+" ")]):_vm._e()]),(_vm.questions && _vm.questions.length
              && _vm.questions[_vm.step - 1].type === _vm.SurveyQuestionTypes.SINGLE_ANSWER)?_c('single-select-component',{key:_vm.questions[_vm.step - 1].uid,class:_vm.fieldClass,attrs:{"choices":_vm.getQuestionChoices(_vm.questions[_vm.step - 1]),"name":_vm.questions[_vm.step - 1].uid,"selected-choice":_vm.getSelectedChoices(_vm.step) ? _vm.getSelectedChoices(_vm.step)[0] : null},on:{"on-select":_vm.onSingleSelectChange}}):_vm._e(),(_vm.questions && _vm.questions.length
              && _vm.questions[_vm.step - 1].type === _vm.SurveyQuestionTypes.MULTIPLE_ANSWER)?_c('multi-select-component',{key:_vm.questions[_vm.step - 1].uid,class:_vm.fieldClass,attrs:{"choices":_vm.getQuestionChoices(_vm.questions[_vm.step - 1]),"selected-choices":_vm.getSelectedChoices(_vm.step) ? _vm.getSelectedChoices(_vm.step) : []},on:{"on-select":_vm.onMultipleSelectChange}}):_vm._e(),(_vm.questions && _vm.questions.length
              && _vm.questions[_vm.step - 1].type === _vm.SurveyQuestionTypes.RATING)?_c('rating-star-component',{key:_vm.questions[_vm.step - 1].uid,attrs:{"value":_vm.getSelectedChoices(_vm.step) ? parseInt(_vm.getSelectedChoices(_vm.step)[0].text, 10) : 0},on:{"on-change":_vm.onRatingChange}}):_vm._e(),(_vm.questions && _vm.questions.length
              && _vm.questions[_vm.step - 1].type === _vm.SurveyQuestionTypes.TEXT_ANSWER)?_c('input-text',{key:_vm.questions[_vm.step - 1].uid,class:_vm.fieldClass,attrs:{"default-value":_vm.getSelectedChoices(_vm.step) ? _vm.getSelectedChoices(_vm.step)[0].text : '',"required":false},on:{"update:defaultValue":_vm.onTextAnswerChange}}):_vm._e(),(_vm.questions && _vm.questions.length
              && _vm.questions[_vm.step - 1].type === _vm.SurveyQuestionTypes.ESSAY_ANSWER)?_c('text-area-component',{key:_vm.questions[_vm.step - 1].uid,staticClass:"text-area-answer",class:_vm.fieldClass,attrs:{"default-value":_vm.getSelectedChoices(_vm.step) ? _vm.getSelectedChoices(_vm.step)[0].text : '',"rows-height":6},on:{"update:defaultValue":_vm.onTextAnswerChange}}):_vm._e(),(_vm.questions.length >= _vm.step && !_vm.isMobile)?_c('div',{staticClass:"d-flex mt-5 overflow-visible"},[_c('button-component',{staticClass:"w-auto mr-2",attrs:{"disabled":_vm.continueButtonDisabled,"text":("" + (_vm.$t('survey.continue-button'))),"size":"md","variant":"primary"},on:{"on-click":_vm.onContinueClick}}),(_vm.step > 1)?_c('button-component',{staticClass:"w-auto",attrs:{"text":("" + (_vm.$t('survey.go-back-button'))),"size":"md","variant":"tertiary"},on:{"on-click":_vm.onGoBackClick}}):_vm._e()],1):_vm._e(),(_vm.questions.length >= _vm.step && _vm.isMobile)?_c('div',{staticClass:"position-fixed\n            buttons-mobile w-100 w-100"},[_c('div',{staticClass:"container d-flex align-items-center justify-content-center column-gap-2"},[(_vm.step > 1)?_c('div',{staticClass:"w-50"},[_c('button-component',{staticClass:"w-100",attrs:{"text":("" + (_vm.$t('survey.go-back-button'))),"size":"md","variant":"tertiary"},on:{"on-click":_vm.onGoBackClick}})],1):_vm._e(),_c('div',{class:[
                  {'w-50': _vm.step > 1},
                  {'w-100': _vm.step === 1} ]},[_c('button-component',{staticClass:"w-100",attrs:{"disabled":_vm.continueButtonDisabled,"text":("" + (_vm.$t('survey.continue-button'))),"size":"md","variant":"primary"},on:{"on-click":_vm.onContinueClick}})],1)])]):_vm._e()],1)]):_vm._e()]):_vm._e(),((_vm.surveyAnswered || _vm.surveyUnavailable) && !_vm.isLoading)?_c('div',{staticClass:"survey-last-step",class:[
        {'lg': _vm.isDesktop},
        {'md': _vm.isTablet},
        {'sm': _vm.isMobile} ]},[_c('div',{staticClass:"w-100 d-flex align-items-center justify-content-center mb-3"},[_c('font-awesome-component',{attrs:{"icon":("fas " + (_vm.surveyAnswered && _vm.survey ? 'fa-check-circle' : '') + " " + (_vm.surveyUnavailable
            ? 'fa-circle-exclamation'
            : '')),"icon-classes":("\n            icon-32 " + (_vm.surveyAnswered && _vm.survey ? 'text-green-g-3-primary-green' : '') + "\n            " + (_vm.surveyUnavailable ? 'text-red-r-3-primary-red' : '') + "\n          "),"container-classes":"icon-48"}})],1),_c('h4',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.surveyAnswered && _vm.survey ? _vm.$t('survey.survey-answered.title') : (_vm.surveyUnavailable ? _vm.$t('survey.survey-unavailable.title') : ''))+" ")]),_c('p',{staticClass:"text-neutral-n-5-placeholder m-0"},[_vm._v(" "+_vm._s(_vm.surveyAnswered && _vm.survey ? _vm.$t('survey.survey-answered.subtitle') : (_vm.surveyUnavailable ? _vm.$t('survey.survey-unavailable.subtitle') : ''))+" ")])]):(_vm.questions.length && _vm.step > _vm.questions.length)?_c('div',{staticClass:"d-flex flex-column survey-last-step",class:[
        {'lg': _vm.isDesktop},
        {'md': _vm.isTablet},
        {'sm': _vm.isMobile} ]},[_c('div',{staticClass:"w-100 d-flex align-items-center justify-content-center mb-2"},[_c('font-awesome-component',{attrs:{"container-classes":"icon-48","icon":"fas fa-check-circle","icon-classes":"icon-32 text-green-g-3-primary-green"}})],1),_c('h4',[_vm._v(" "+_vm._s(_vm.$t('survey.last-step.title'))+" ")]),_c('p',{staticClass:"text-neutral-n-5-placeholder m-0"},[_vm._v(" "+_vm._s(_vm.$t('survey.last-step.subtitle'))+" ")]),_c('div',{staticClass:"mt-5 d-flex align-items-center justify-content-center"},[_c('div',{class:[
            'd-flex flex-column row-gap-2',
            {'last-step-buttons-lg': _vm.isDesktop},
            {'last-step-buttons-md': _vm.isTablet},
            {'w-100': _vm.isMobile}
          ]},[_c('button-component',{attrs:{"bold":true,"loading-with-text":_vm.saveLoading,"text":_vm.saveLoading
              ? ("" + (_vm.$t('survey.last-step.submitting-button')))
              : ("" + (_vm.$t('survey.last-step.submit-button'))),"size":"md","variant":"primary"},on:{"on-click":_vm.onClose}}),_c('button-component',{attrs:{"bold":true,"disabled":_vm.saveLoading,"text":("" + (_vm.$t('survey.last-step.review-button'))),"size":"md","variant":"tertiary"},on:{"on-click":function($event){_vm.step = 1}}})],1)])]):_vm._e()]),_c('confirm-modal',{attrs:{"cancel-text":("" + (_vm.$t('survey.leave-modal.cancel-text'))),"description":("" + (_vm.$t('survey.leave-modal.description'))),"ok-text":("" + (_vm.$t('survey.leave-modal.ok-text'))),"title":("" + (_vm.$t('survey.leave-modal.title'))),"user-id":0,"type":"leave-modal"},on:{"on-confirm":_vm.onLeave}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }